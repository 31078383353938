<bit-layout variant="secondary">
  <bit-side-nav *ngIf="provider$ | async as provider">
    <bit-nav-logo [openIcon]="logo" route="." [label]="'providerPortal' | i18n"></bit-nav-logo>

    <bit-nav-item
      icon="bwi-bank"
      [text]="'clients' | i18n"
      [route]="(hasConsolidatedBilling$ | async) ? 'manage-client-organizations' : 'clients'"
    ></bit-nav-item>
    <bit-nav-group
      icon="bwi-sliders"
      [text]="'manage' | i18n"
      route="manage"
      *ngIf="showManageTab(provider)"
    >
      <bit-nav-item
        [text]="'people' | i18n"
        route="manage/people"
        *ngIf="provider.canManageUsers"
      ></bit-nav-item>
      <bit-nav-item
        [text]="'eventLogs' | i18n"
        route="manage/events"
        *ngIf="provider.useEvents"
      ></bit-nav-item>
    </bit-nav-group>
    <bit-nav-group
      icon="bwi-billing"
      [text]="'billing' | i18n"
      route="billing"
      *ngIf="canAccessBilling$ | async"
    >
      <bit-nav-item [text]="'subscription' | i18n" route="billing/subscription"></bit-nav-item>
      <bit-nav-item [text]="'billingHistory' | i18n" route="billing/history"></bit-nav-item>
    </bit-nav-group>
    <bit-nav-item
      icon="bwi-cogs"
      [text]="'settings' | i18n"
      route="settings"
      *ngIf="showSettingsTab(provider)"
    ></bit-nav-item>

    <ng-container slot="footer">
      <navigation-product-switcher></navigation-product-switcher>
      <app-toggle-width></app-toggle-width>
    </ng-container>
  </bit-side-nav>

  <app-payment-method-warnings
    *ngIf="showPaymentMethodWarningBanners$ | async"
  ></app-payment-method-warnings>
  <router-outlet></router-outlet>
</bit-layout>

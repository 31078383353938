<form [formGroup]="formGroup" [bitSubmit]="submit">
  <div class="tw-mb-4 tw-text-lg">
    <bit-radio-group formControlName="paymentMethod">
      <bit-radio-button id="card-payment-method" [value]="PaymentMethodType.Card">
        <bit-label>
          <i class="bwi bwi-fw bwi-credit-card" aria-hidden="true"></i>
          {{ "creditCard" | i18n }}
        </bit-label>
      </bit-radio-button>
      <bit-radio-button
        id="bank-payment-method"
        [value]="PaymentMethodType.BankAccount"
        *ngIf="showBankAccount"
      >
        <bit-label>
          <i class="bwi bwi-fw bwi-bank" aria-hidden="true"></i>
          {{ "bankAccount" | i18n }}
        </bit-label>
      </bit-radio-button>
      <bit-radio-button
        id="paypal-payment-method"
        [value]="PaymentMethodType.PayPal"
        *ngIf="showPayPal"
      >
        <bit-label>
          <i class="bwi bwi-fw bwi-paypal" aria-hidden="true"></i>
          {{ "payPal" | i18n }}
        </bit-label>
      </bit-radio-button>
      <bit-radio-button
        id="credit-payment-method"
        [value]="PaymentMethodType.Credit"
        *ngIf="showAccountCredit"
      >
        <bit-label>
          <i class="bwi bwi-fw bwi-dollar" aria-hidden="true"></i>
          {{ "accountCredit" | i18n }}
        </bit-label>
      </bit-radio-button>
    </bit-radio-group>
  </div>
  <!-- Card -->
  <ng-container *ngIf="usingCard">
    <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-mb-4">
      <div class="tw-col-span-1">
        <label for="stripe-card-number">{{ "number" | i18n }}</label>
        <div id="stripe-card-number" class="form-control stripe-form-control"></div>
      </div>
      <div class="tw-col-span-1 tw-flex tw-items-end">
        <img
          src="../../images/cards.png"
          alt="Visa, MasterCard, Discover, AmEx, JCB, Diners Club, UnionPay"
          class="tw-max-w-full"
        />
      </div>
      <div class="tw-col-span-1">
        <label for="stripe-card-expiry">{{ "expiration" | i18n }}</label>
        <div id="stripe-card-expiry" class="form-control stripe-form-control"></div>
      </div>
      <div class="tw-col-span-1">
        <div class="tw-flex">
          <label for="stripe-card-cvc">
            {{ "securityCode" | i18n }}
          </label>
          <a
            href="https://www.cvvnumber.com/cvv.html"
            tabindex="-1"
            target="_blank"
            rel="noreferrer"
            class="ml-auto"
            appA11yTitle="{{ 'learnMore' | i18n }}"
          >
            <i class="bwi bwi-question-circle" aria-hidden="true"></i>
          </a>
        </div>
        <div id="stripe-card-cvc" class="form-control stripe-form-control"></div>
      </div>
    </div>
  </ng-container>
  <!-- Bank Account -->
  <ng-container *ngIf="showBankAccount && usingBankAccount">
    <app-callout type="warning" title="{{ 'verifyBankAccount' | i18n }}">
      {{ "verifyBankAccountInitialDesc" | i18n }} {{ "verifyBankAccountFailureWarning" | i18n }}
    </app-callout>
    <div class="tw-grid tw-grid-cols-2 tw-gap-4" formGroupName="bankInformation">
      <bit-form-field class="tw-col-span-1" disableMargin>
        <bit-label>{{ "routingNumber" | i18n }}</bit-label>
        <input
          bitInput
          id="routingNumber"
          type="text"
          formControlName="routingNumber"
          required
          appInputVerbatim
        />
      </bit-form-field>
      <bit-form-field class="tw-col-span-1" disableMargin>
        <bit-label>{{ "accountNumber" | i18n }}</bit-label>
        <input
          bitInput
          id="accountNumber"
          type="text"
          formControlName="accountNumber"
          required
          appInputVerbatim
        />
      </bit-form-field>
      <bit-form-field class="tw-col-span-1" disableMargin>
        <bit-label>{{ "accountHolderName" | i18n }}</bit-label>
        <input
          id="accountHolderName"
          bitInput
          type="text"
          formControlName="accountHolderName"
          required
          appInputVerbatim
        />
      </bit-form-field>
      <bit-form-field class="tw-col-span-1" disableMargin>
        <bit-label>{{ "bankAccountType" | i18n }}</bit-label>
        <bit-select id="accountHolderType" formControlName="accountHolderType" required>
          <bit-option [value]="''" label="-- {{ 'select' | i18n }} --"></bit-option>
          <bit-option
            [value]="'company'"
            label="{{ 'bankAccountTypeCompany' | i18n }}"
          ></bit-option>
          <bit-option
            [value]="'individual'"
            label="{{ 'bankAccountTypeIndividual' | i18n }}"
          ></bit-option>
        </bit-select>
      </bit-form-field>
    </div>
  </ng-container>
  <!-- PayPal -->
  <ng-container *ngIf="showPayPal && usingPayPal">
    <div class="tw-mb-3">
      <div id="braintree-container" class="tw-mb-1 tw-content-center"></div>
      <small class="tw-text-muted">{{ "paypalClickSubmit" | i18n }}</small>
    </div>
  </ng-container>
  <!-- Account Credit -->
  <ng-container *ngIf="showAccountCredit && usingAccountCredit">
    <app-callout type="info">
      {{ "makeSureEnoughCredit" | i18n }}
    </app-callout>
  </ng-container>
  <button *ngIf="!!onSubmit" bitButton bitFormButton buttonType="primary" type="submit">
    {{ "submit" | i18n }}
  </button>
</form>
